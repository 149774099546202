import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';

import { MessagesListComponent } from './components/messages-list/messages-list.component';
import { RequestsListComponent } from './components/requests-list/requests-list.component';
import { AnswerProposalComponent } from './components/answer-proposal/answer-proposal.component';
import { UploadReceiptComponent } from './components/upload-receipt/upload-receipt.component';


const routes: Routes = [
  {path: '', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'requests-list', component: RequestsListComponent , canActivate: [AuthGuard]},
  
  { path: 'messages-list', component: MessagesListComponent , canActivate: [AuthGuard]},
 
  { path: 'answer-proposal', component: AnswerProposalComponent , canActivate: [AuthGuard]},
  
  { path: 'upload-receipt', component: UploadReceiptComponent , canActivate: [AuthGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] }
];

@NgModule({
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes) ]
})

export class AppRoutingModule { }
