import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
//import { ProvidersService } from '../../services/providers.service';
import { RouteParams } from '../route-params/route-params.component';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription } from 'rxjs/Subscription';
import { RequestsDataSource } from '../../services/requests.datasource';


@Component({
  selector: 'app-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.css']
})
export class RequestsListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;
  dataSource: RequestsDataSource;

  displayedColumns = ['createdTs', 'type', 'plate','dateend', 'instype','totalAmount','proposedAmount', 'action'];
  subscription: Subscription;

  constructor(private afs: AngularFirestore, private args: RouteParams, private router: Router,
    private flashMessagesService: FlashMessagesService, private dialog: MatDialog, public afAuth: AngularFireAuth) {

  }
 
   back(){     
     this.router.navigate(['messages-list']);
    }
  
  ngOnInit() {
    if(!this.args.params){
      this.back();
    }
    //TODO: add paging with the firestore limit
    this.subscription = this.afs.collection<any>('brokers_comms', ref => ref.where('messageId', '==', this.args.params.id).where('from', '==', 'U').limit(1000)).snapshotChanges().subscribe(d => {
      // console.log('data streaming' + JSON.stringify(d));
      this.dataSource = new RequestsDataSource(this.paginator, this.sort);
      this.dataSource.data = d.map(snap => {
        const id = snap.payload.doc.id;
        const data = { id, ...snap.payload.doc.data() };
        return data;
      });

    });
  }

  propose(request){ 
    //console.info(JSON.stringify(message))
    this.args.params = request;
    this.router.navigate(['answer-proposal']);
   }

   uploadRecipt(request){ 
    //console.info(JSON.stringify(message))
    this.args.params = request;
    this.router.navigate(['upload-receipt']);
   }

  addCommand(data: any) {
    console.log('add command');
    this.afs.collection<any>('commands').doc(data.id).set(data);
  }

  duplicateCommand(data: any) {
    this.flashMessagesService.show('Command duplicated', { cssClass: 'alert-danger', timeout: 4000 });
    const prevCommand = this.afs.collection<any>('commands').doc(data.id).valueChanges();
    prevCommand.subscribe(value => {
      this.afs.collection<any>('commands').add(value);
    });


  }
  deleteCommand(prov: any) {
    this.afs.collection<any>('commands').doc(prov.id).delete();
  }
}
