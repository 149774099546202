import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
//import { ProvidersService } from '../../services/providers.service';
import { RouteParams } from '../route-params/route-params.component';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription } from 'rxjs/Subscription';
import { RequestsDataSource } from '../../services/requests.datasource';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from '../alert/alert.component';
import { FormGroup } from '@angular/forms';
import * as firebase from 'firebase';

@Component({
  selector: 'app-answer-proposal',
  templateUrl: './answer-proposal.component.html',
  styleUrls: ['./answer-proposal.component.css']
})
export class AnswerProposalComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;
   
  subscription: Subscription;

  public proposedDeduct:number;
  public proposedAmount:number;
  public proposedNotes:string = "";
  public proposedPeriod:number;

  public flagForm: FormGroup;

  constructor(private simpleModalService: SimpleModalService,  private afs: AngularFirestore, private args: RouteParams, private router: Router,
    private flashMessagesService: FlashMessagesService, private dialog: MatDialog, public afAuth: AngularFireAuth) {

  }

  ngOnInit() {
   // console.log(JSON.stringify(this.args));    
    if(!this.args.params){
      this.back();
    }
  }

  back(){     
    this.router.navigate(['requests-list']);
   }

   async submitProposal(){ 
    //this.simpleModalService.addModal(AlertComponent, {title: 'Alert title!', message: 'deduct:'+this.proposedDeduct});
    //this.afs.collection<any>('brokers_comms').doc(this.args.params.id).set({proposedAmount:this.proposedAmount},{merge:true});
   // console.info( this.args.params.uid)
   await this.afs.collection<any>('brokers_comms').doc(this.args.params.id).set({done:true},{merge:true});
   await this.afs.collection<any>('messages').doc(this.args.params.messageId).set({pendAction:false},{merge:true});
    let bcRef = await this.afs.collection<any>('brokers_comms').add({
      proposedAmount:this.proposedAmount,
      proposedDeduct:this.proposedDeduct,
      proposedPeriod:this.proposedPeriod,
      type: 'INSURANCE',
      billId: this.args.params.billId,
      otp: 'PRSA',
      from: 'C',
      seq: (this.args.params.seq + 1),
      messageId: this.args.params.messageId,
      notes: this.proposedNotes,
      userId: this.args.params.userId,
      plate: this.args.params.plate,
      totalAmount:this.args.params.totalAmount,
      instype: this.args.params.instype,
      dateend: this.args.params.dateend,
      createdTs: firebase.firestore.FieldValue.serverTimestamp(),
    });
    console.info(bcRef.id)
    this.back();


    
   }

   

}


