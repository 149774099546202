import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlashMessagesModule } from 'angular2-flash-messages';


import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
//import { ProvidersListComponent, DialogGetProviderComponent } from './components/providers-list/providers-list.component';
import { EditorModule } from '@tinymce/tinymce-angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatCardModule,
  MatGridListModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatListModule,
  MatIconModule,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatRadioModule,
  MatSelectModule
} from '@angular/material';



import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
//import { ProvidersService } from './services/providers.service';
//import { EditProviderComponent } from './components/edit-provider/edit-provider.component';
import { RouteParams } from './components/route-params/route-params.component';

import { FlexLayoutModule } from '@angular/flex-layout';



//import { SuspectbillListComponent } from './components/suspectbill-list/suspectbill-list.component';
//import { SuspectbillsService } from './services/suspectbills.service';
//import { InerrorbillListComponent } from './components/inerrorbill-list/inerrorbill-list.component';
//import { InerrorbillsService } from './services/inerrorbills.service';
//import { CommmandsListComponent } from './components/commmands-list/commmands-list.component';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
//import { AllbillListComponent } from './components/allbill-list/allbill-list.component';
import { MessagesListComponent } from './components/messages-list/messages-list.component';
import { RequestsListComponent } from './components/requests-list/requests-list.component';
import { AnswerProposalComponent } from './components/answer-proposal/answer-proposal.component';
import { NgxMaskModule } from 'ngx-mask'
import { SimpleModalModule } from 'ngx-simple-modal';
import { AlertComponent } from './components/alert/alert.component';
import { UploadReceiptComponent } from './components/upload-receipt/upload-receipt.component';
import { FileUploadModule } from 'ng2-file-upload';
//export const options: Partial<IConfig> | (() => Partial<IConfig>);


@NgModule({
  declarations: [
    AppComponent,
    AppNavbarComponent,
    //ProvidersListComponent,
    LoginComponent,
    DashboardComponent,
    //EditProviderComponent,
    //DialogGetProviderComponent,
    //SuspectbillListComponent,
    //InerrorbillListComponent,
    //CommmandsListComponent,
    RequestsListComponent,
    MessagesListComponent,
    AnswerProposalComponent,
    UploadReceiptComponent,
    AlertComponent,
    // RouteParamsComponent
  ],
  entryComponents: [
    //DialogGetProviderComponent
    AlertComponent
  ],
  imports: [
    NgbModule.forRoot(),
    NgxMaskModule.forRoot(),
    SimpleModalModule.forRoot({container: "modal-container"}),
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    // AngularFirestoreModule,
    AngularFireAuthModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatGridListModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    FormsModule,
    FlashMessagesModule.forRoot(),
    EditorModule,
    AngularFireStorageModule,
    ReactiveFormsModule 
  ],
  providers: [AuthService,
    AuthGuard,
    AngularFireStorage,
     // ProvidersService,
    //SuspectbillsService,
    //InerrorbillsService,
    RouteParams],
  bootstrap: [AppComponent]
})
export class AppModule { }
