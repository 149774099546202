import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as  firebase  from "firebase/app";

@Injectable()
export class AuthService {

  constructor(public afAuth: AngularFireAuth, private router: Router, private flashMessagesService: FlashMessagesService) { 
    
    this.afAuth.auth.setPersistence("session");
  }
  public loggedIn = new BehaviorSubject<boolean>(false); // {1}

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }
  // login user
  login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password)
        .then(userData => {
          // sucessfull logedin check if it is the master brokers!!!
          if (email === 'gil.loureiro.76@gmail.com') {
         this.loggedIn.next(true);
         // this.flashMessagesService.show('You are logged in', { cssClass: 'alert-success', timeout: 4000 });
          resolve(userData);
          } else {
            reject('not the super master!');
          }
        },
        err => reject(err));
    });
  }

  // Check user status
  getAuth() {
    // returns an observable to get a user data - wether the user is logged in or not
    return this.afAuth.authState.map(auth => auth);
  }

  logOut() {
    this.loggedIn.next(false);
    this.afAuth.auth.signOut();
    this.router.navigate(['/login']);
  }
/*
  register(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password)
        .then(userData => resolve(userData),
        err => reject(err));
    });
  }*/

}
