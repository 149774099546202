import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
//import { ProvidersService } from '../../services/providers.service';
import { RouteParams } from '../route-params/route-params.component';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription } from 'rxjs/Subscription';
import { MessagesDataSource } from '../../services/messages.datasource';




@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.css']
})
export class MessagesListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;
  dataSource: MessagesDataSource;

  displayedColumns = ['done', 'dueDate', 'read', 'title', 'userId', 'wgtId','action'];
  subscription: Subscription;

  constructor(private afs: AngularFirestore, private args: RouteParams, private router: Router,
    private flashMessagesService: FlashMessagesService, private dialog: MatDialog, public afAuth: AngularFireAuth) {

  }

  ngOnInit() {
    //TODO:  add th epagination using the firestrtore limit
    this.subscription = this.afs.collection<any>('messages', ref => ref.where('wgtId', '==', 'INSURANCE_OFFER').where('pendAction', '==', true).limit(1000)).snapshotChanges().subscribe(d => {
      // console.log('data streaming' + JSON.stringify(d));
      this.dataSource = new MessagesDataSource(this.paginator, this.sort);
      this.dataSource.data = d.map(snap => {
        const id = snap.payload.doc.id;
        const data = { id, ...snap.payload.doc.data() };
        return data;
      });
    });
  }

  doAction(msg) { 
    this.args.params = msg;
    this.router.navigate(['requests-list']);
   }

  addCommand(data: any) {
    console.log('add command');
    this.afs.collection<any>('commands').doc(data.id).set(data);
  }

  duplicateCommand(data: any) {
    this.flashMessagesService.show('Command duplicated', { cssClass: 'alert-danger', timeout: 4000 });
    const prevCommand = this.afs.collection<any>('commands').doc(data.id).valueChanges();
    prevCommand.subscribe(value => {
      this.afs.collection<any>('commands').add(value);
    });


  }
  deleteCommand(prov: any) {
    this.afs.collection<any>('commands').doc(prov.id).delete();
  }
}
