// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCm0eoeFjOsvo3H1WmlF5Fa5m6qg1PXeB4',
    authDomain: 'careduct-260476.firebaseapp.com',
    databaseURL: 'https://careduct-260476.firebaseio.com',
    projectId: 'careduct-260476',
   // storageBucket: 'careduct-260476.appspot.com',
    storageBucket: 'cdbs2',
    messagingSenderId: '136826160471'
  }
};
