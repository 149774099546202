import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
//import { ProvidersService } from '../../services/providers.service';
import { RouteParams } from '../route-params/route-params.component';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription } from 'rxjs/Subscription';
import { RequestsDataSource } from '../../services/requests.datasource';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from '../alert/alert.component';
import { FormGroup, FormControl } from '@angular/forms';
import * as firebase from 'firebase';
import { FileUploader } from 'ng2-file-upload';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { SHA256, enc } from "crypto-js";
import { HttpResponse } from '@angular/common/http';
//import { Http, ResponseContentType } from '@angular/http';
import axios from 'axios';
import * as shajs from 'sha.js';
import { ProvidersDataSource } from '../../services/providers.datasource';
//import * as Buffer from "Buffer";


@Component({
  selector: 'app-upload-receipt',
  templateUrl: './upload-receipt.component.html',
  styleUrls: ['./upload-receipt.component.css']
})
export class UploadReceiptComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;

  subscription: Subscription;

  public proposedDeduct: number;
  public proposedAmount: number;
  public proposedNotes: string = "";
  public proposedPeriod: number;
  public reciptDueDate: Date;

  public providerShortName: string;
  public providerLongName: string;
  public providerLogoUrl: string;
  public providerId: string;

  public providers: any;
  public dataSource: any;
  public selected: any;
  public dueDate = new FormControl(new Date());
  public dateBegin = new FormControl(new Date());
  public dateEnd = new FormControl(new Date());

  public flagForm: FormGroup;

  constructor(private simpleModalService: SimpleModalService, private afs: AngularFirestore, private args: RouteParams, private router: Router,
    private flashMessagesService: FlashMessagesService, private dialog: MatDialog, public afAuth: AngularFireAuth, private afStorage: AngularFireStorage) {

  }

  ngOnInit() {
    // console.log(JSON.stringify(this.args));    
    if (!this.args.params) {
      this.back();
    }
    this.proposedAmount = this.args.params.proposedAmount;
    this.proposedDeduct = this.args.params.proposedDeduct;
    this.proposedPeriod = this.args.params.proposedPeriod;
    this.subscription = this.afs.collection<any>('providers', ref => ref.where('area', '==', 'INSURANCE').limit(1000)).snapshotChanges().subscribe(d => {
      // console.log('data streaming' + JSON.stringify(d));
      this.dataSource = new ProvidersDataSource(this.paginator, this.sort);
      this.dataSource.data = d.map(snap => {
        const id = snap.payload.doc.id;
        const data = { id, ...snap.payload.doc.data() };
        return data;
      });
    });

   
  }

  //download the PDF to calcultae the HASH
  async getPdf(url) {
    return await axios.get(url, {
      method: 'GET',
      //responseType: 'blob',
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }
    });


  }



  //upload the file into the firestorage
  ref: any;
  task: any;
  uploadProgress: Observable<number>;
  fileLoaded: boolean = false;
  pdfShaHash: any;
  inBucketPathPDF: any;
  upload(event) {
    this.fileLoaded = false;

    // create a random id
    const randomId = Math.random().toString(36).substring(2);
    const now = new Date()
    const secondsSinceEpoch = Math.round(now.getTime() / 1000)
    // create a reference to the storage bucket location
    this.inBucketPathPDF = '/' + this.args.params.userId + '/' + (new Date()).getFullYear() + '/' + ((new Date()).getMonth() + 1) + '/' + randomId + '/' + secondsSinceEpoch + ".pdf";

    this.ref = this.afStorage.ref(this.inBucketPathPDF);
    // the put method creates an AngularFireUploadTask
    // and kicks off the upload
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges();
    // this.downloadURL = this.task.downloadURL();
    var self = this;

    // get notified when the download URL is available
    this.task.snapshotChanges().pipe(
      finalize(function () {
        //console.info(self.ref.getDownloadURL())
        self.ref.getDownloadURL().subscribe(async function (downloadURL) {
          console.info(downloadURL)
          let data = await self.getPdf(downloadURL);
          let buff = new Buffer(data.data);
          self.pdfShaHash = shajs('sha256').update(buff).digest('base64')
          //let base64data = buff.toString('base64');
          //const pdfShaHash = SHA256(buff).toString(enc.Base64);
          // console.log(pdfShaHash);
          self.fileLoaded = true;
        });

        //console.info(self.ref.getMetadata())

      })
    )
      .subscribe()
  }

  //get back to the message list
  back() {
    this.router.navigate(['messages-list']);
  }


  // 3 third step - PRSA
  async submitReceipt() {

    var pdfPaths = {};

    //get the user
    let user = await this.afs.collection('users').doc(this.args.params.userId).get().first().toPromise();;
    //get the data from the selected provider
    let provider = this.dataSource.data.find(element => element.id === this.selected);
    pdfPaths["paths"] = [];
    let userName;
    if (user.data().displayName) {
      userName = user.data().displayName;
    } else if (user.data().email) {

      userName = user.data().email;
    } else if (user.data().nick) {

      userName = user.data().nick;
    } else {

      userName = "";
    }
    let sharedToUsers = {}
    sharedToUsers[this.args.params.userId] = 2;
    //insert the new bill
    let billData = {
      draft: false,
      totalAmount: this.args.params.proposedAmount,
      pdfPath: { first: 1, paths: [this.inBucketPathPDF] },
      contract: {
        type: this.args.params.type,
        ced20: true,
        ced: 40,
        ack: false,
        dateBegin: this.dateBegin.value,
        dateEnd: this.dateEnd.value
      },
      state: 'ok',
      currency: 'EUR',
      pdfHash: this.pdfShaHash,
      provider: { shortName: provider.shortName, longName: provider.longName, logoUrl: provider.logoUrl, id: provider.id },
      userId: this.args.params.userId,
      tz: user.data().tz,
      emailId: "0",
      dateCollected: new Date(),
      userName: userName,
      sharedToUsers: sharedToUsers,
      dueDate: this.dueDate.value,
      status: { paid: 0 },
      notifications: { nb: false, dd: true },
    }
    if (this.args.params.plate)
      billData.contract["plate"] = this.args.params.plate;
    //console.info("Storing:"+JSON.stringify(result));
    //userId: userRef.data().userId,
    // console.info(JSON.stringify(result));
    //console.info("Created bill with ID: " + userRef.data().userId + '_' + pdfHash.replaceAll("[\\/]", "_"))

    //console.info("we dont have a bill ref")
    let newBillId = this.args.params.userId + '_' + this.pdfShaHash.replace(/[\\/]/g, "_");
    console.info(newBillId)
    console.info(JSON.stringify(billData))
    //a.replace(/:/g,"hi");
     await this.afs.collection('bills').doc(newBillId).set(billData, { merge: true });
    //let billRef = await this.afs.collection('bills').add(billData);


    //add th eanswer with the receipt
    let bcRef = await this.afs.collection<any>('brokers_comms').add({
      proposedAmount: this.proposedAmount,
      proposedDeduct: this.proposedDeduct,
      proposedPeriod: this.proposedPeriod,
      type: 'INSURANCE',
      billId: newBillId,
      otp: 'RCPS',
      from: 'C',
      seq: (this.args.params.seq + 1),
      messageId: this.args.params.messageId,
      notes: this.proposedNotes,
      userId: this.args.params.userId,
      plate: this.args.params.plate,
      totalAmount: this.args.params.totalAmount,
      instype: this.args.params.instype,
      dateend: this.args.params.dateend,
      createdTs: firebase.firestore.FieldValue.serverTimestamp(),
    });
    console.info(bcRef.id)
    //end the operation
    await this.afs.collection<any>('brokers_comms').doc(this.args.params.id).set({ done: true }, { merge: true });
    await this.afs.collection<any>('messages').doc(this.args.params.messageId).set({ pendAction: false }, { merge: true });
    this.back();



  }



}


