import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import 'rxjs/Rx';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    public afAuth: AngularFireAuth,
  public auth: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    // Check if the user is logged in
    return this.afAuth.authState.map(auth => {
      // Check for auth

      if (!auth) {
          // Redirect to login
          this.router.navigate(['/login']);
          return false;
      } else {
          // Because we are logged in and can visit this route
          this.auth.loggedIn.next(true);
          return true;

      }

  });
  }
}
